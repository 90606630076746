// third-party
import { createSlice } from '@reduxjs/toolkit';

// project-imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ==============================|| SLICE - PRODUCTS ||============================== //

// const initialState = {
//   error: null,
//   products: [],
//   product: null,
//   relatedProducts: [],
//   reviews: [],
//   addresses: []
// };
const initialState = {
  error: null,
  product: null,
  filter: {
    total_count: 0,
    min_price: 0,
    max_price: 0,
    products: [],
    colors: [],
    sizes: [],
    product_types: [],
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // FILTER PRODUCTS
    // filterProductsSuccess(state, action) {
    //   state.products = action.payload;
    // },

    filterProductsSuccess(state, action) {
      state.filter.total_count = action.payload.total_count;
      state.filter.min_price = action.payload.min_price;
      state.filter.max_price = action.payload.max_price;
      state.filter.products = action.payload.product_list;
      state.filter.colors = action.payload.colors;
      state.filter.sizes = action.payload.sizes;
      state.filter.product_types = action.payload.product_types;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.product = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedProductsSuccess(state, action) {
      state.relatedProducts = action.payload;
    },

    // GET PRODUCT REVIEWS
    getProductReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getProducts() {
  return async () => {
    try {
      const response = await axios.get('/admin/product');
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/store/new-order', { filter });
      dispatch(slice.actions.filterProductsSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(id) {
  return async () => {
    try {
      const response = await axios.post('/store/product-details', { product_id: id });
      dispatch(slice.actions.getProductSuccess(response.data.result.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedProducts(id) {
  return async () => {
    try {
      const response = await axios.post('/api/product/related', {id});
      dispatch(slice.actions.getRelatedProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getProductReviewsSuccess(response.data.productReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
